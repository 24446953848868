import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './views/Login';
import FiveGame from './views/FiveGame';
import TenGame from './views/TenGame';
import Counter from './views/Counter';
import Transactions from './views/Transactions';
import PrivateRoute from "./common/PrivateRoute";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/five-game" element={
          <PrivateRoute>
            <FiveGame />
          </PrivateRoute>
        } />
        <Route path="/ten-game" element={
          <PrivateRoute>
            <TenGame />
          </PrivateRoute>
        } />
        <Route path="/counter" element={
          <PrivateRoute>
            <Counter />
          </PrivateRoute>
        } />
        <Route path="/transactions" element={
          <PrivateRoute>
            <Transactions />
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;
