import React, { useEffect, useState } from 'react'
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import '../assets/css/style.css';
import Api from '../common/Api';
import { enCreption, decryptData } from '../common/Aes';
import { Icons, ToastContainer, toast } from 'react-toastify';
import ToastObj from '../common/ToastObj';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';

export default function Counter() {
  const navigate = useNavigate();
  const { authData, logout } = useAuth();
  const auth_user = decryptData(authData);
  // Example data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gameId, setGameId] = useState('');
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [total, setTotals] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [receipt, setReceipt] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const itemsPerPage = 25;
  let totalAmount = 0;
  let totalQuantity = 0;
  let totalWinQty = 0;
  let totalWinAmount = 0;

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    fetchPageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (clearFilter)
      fetchPageData(0);
    return setClearFilter(false);
  }, [gameId,startDate,endDate]);

  const fetchPageData = async (page) => {
    try {
      const payload = await enCreption({ vendor_id: auth_user.id, page: page + 1, limit: itemsPerPage, from_date: startDate, to_date: endDate, game_id: gameId });
      await Api.post(`counter`, payload)
        .then(res => {
          const response = res.data;
          setLoading(false);
          if (response.status === "success") {
            setData(response.items);
            setTotalPages(response.totalPages);
            setOpeningBalance(response.opening);
            setClosingBalance(response.closing);
            setTotals(response.total);
          } else if (response.status === "error") {
            toast.error(response.msg, ToastObj);
          } else {
            toast.error("Something went wrong !!!", ToastObj);
          }
        });
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleDateFilter = () => {
    if (new Date(endDate) < new Date(startDate)) {
      Swal.fire({icon: "error",text:'End date cannot be earlier than start date.',allowOutsideClick:false});
      return;
    }
    setLoading(true);
    fetchPageData(0); // Reset to first page when filtering
  };

  const handleClearFilter = () => {
    setClearFilter(true);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setGameId('');
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  const handleReceipt = async (id) => {
    try {
      const payload = await enCreption({ vendor_id: auth_user.id, bid_id: id });
      await Api.post(`receipt`, payload)
        .then(res => {
          const response = res.data;
          if (response.status === "success") {
            Swal.fire({
              title: "GALAXY",
              html: generateReceiptContent(response.receipt),
              showCancelButton: false,
              confirmButtonText: "Okay",
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading()
            });
            setReceipt(response.receipt);
          } else if (response.status === "error") {
            toast.error(response.msg, ToastObj);
          } else {
            toast.error("Something went wrong !!!", ToastObj);
          }
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };

  const generateReceiptContent = (data) => {
    if (!data) return '';
    let total_qty = 0;
    let total = 0;
    const itemsHtml = data.map(item => {
      total_qty += parseInt(item.quantity);
      total += parseInt(item.amount);
      return (
        `<tr>
      <td>${item.hindi}</td>
      <td>${item.quantity}</td>
      <td>x ${item.rate}</td>
      <td>${item.amount}</td>
    </tr>
    `)
    }).join('');

    return `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; font-size: 14px; width: 100%; margin:0; padding:0}
            h3 { text-align: center;}
            table { width: 100%; }
            th, td { padding: 3px; text-align: left; }
            .custom-table {
              border: 1px solid gray;
              border-collapse: collapse;
            }
            .custom-table td,
            .custom-table th,
            .custom-table tr {
                border: 1px solid gray;
            }     
          </style>
        </head>
        <body>
          <table class="custom-table">
            <thead>
              <tr><td colspan="4"><h3>Receipt No. : ${data[0].receipt_no} </h3></td></tr>
              <tr>
                <th>Yantra</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Totals</th>
              </tr>
            </thead>
            <tbody>
              ${itemsHtml}
              <tr>
              <th>Total</th>
              <th>${total_qty}</th>
              <td></td>
              <th>${total}</th>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
    `;
  };

  return (
    <>
      <main>
        <nav className="sticky-top custom_nav navbar navbar-expand-sm">
          <div className="container-fluid">
            <a className="navbar-brand" href=""><img src="assets/theme/images/galaxy-logo.png" className="img-fluid" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="d-flex right_text">
              <div><h4>{auth_user.vendor_id}</h4></div>
              <div><h4>{"Counter"}</h4></div>
              <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/transactions') }}>{"Transactions"}</h4></div>
              <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/five-game') }}>{"5 Min Game"}</h4></div>
              <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/ten-game') }}>{"10 Min Game"}</h4></div>
            </div>
          </div>
        </nav>
        <section>
          <div className="container mt-1">
            <h2 className="mb-1 text-center">Biddings</h2>
            <div className="mb-1">
              <div className="row justify-content-center">
                <div className="col-md-3 mb-2">
                  <label htmlFor="startDate" className="form-label">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <label htmlFor="endDate" className="form-label">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <label htmlFor="gameId" className="form-label">Draw ID</label>
                  <input
                    type="text"
                    placeholder='Enter Draw ID'
                    className="form-control"
                    id="gameId"
                    value={gameId}
                    onChange={(e) => setGameId(e.target.value)}
                  />
                </div>
                <div className="col-md-3 d-flex justify-content-center align-items-end mb-2">
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleDateFilter}
                  >
                    Filter
                    {loading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="table-responsive" style={{ maxHeight: '450px' }}>
              <table className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td colSpan="8"><b>Opening Balance</b></td>
                    <td><b>{openingBalance}</b></td>
                    <td><b>Total Wons</b></td>
                    <td><b>Excess</b></td>
                  </tr>
                  {total &&
                    <>
                      <tr>
                        <td colSpan={8}><b>Total Purchase</b></td>
                        <td>{total.amount && <b>{total.quantity} x 11 = {total.amount}</b>}</td>
                        <td>{total.win_amount && <b>{total.win_qty} x 11 = {total.win_amount}</b>}</td>
                        <td><b>{(!isNaN(parseInt(total.amount) - parseInt(total.win_amount))) ? (parseInt(total.amount) - parseInt(total.win_amount)) : 0}</b></td>
                      </tr>
                    </>
                  }
                  <tr>
                    <td colSpan="8"><b>Closing Balance</b></td>
                    <td colSpan={3}><b>{closingBalance}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive" style={{ maxHeight: '50vh' }}>
              <table className="table table-striped table-bordered">
                <thead className="table-light">
                  <tr>
                    <th>Draw ID</th>
                    <th>Type</th>
                    <th>Draw Date</th>
                    <th>Draw Time</th>
                    <th>Receipt ID</th>
                    <th>Purchase Time</th>
                    <th>Purchase Qty</th>
                    <th>Total</th>
                    <th>Won Yantra</th>
                    <th>Won Qty</th>
                    <th>Jackpot</th>
                    <th>Won Amount</th>
                    <th>Claimed</th>
                    <th>Claimed Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map(item => {
                      totalAmount += parseInt(item.amount);
                      totalQuantity += parseInt(item.quantity);
                      totalWinQty += (item.is_won == 1 && parseInt(item.win_qty));
                      totalWinAmount += (item.win_amount && parseInt(item.win_amount));
                      return (
                        <tr key={item.id}>
                          <td>{item.game_id}</td>
                          <td>{item.game_type}</td>
                          <td>{item.date}</td>
                          <td>{item.draw_time}</td>
                          <td>{item.id}</td>
                          <td>{item.purchase_time}</td>
                          <td><a style={{ cursor: "pointer", color: "red" }} onClick={(e) => { e.preventDefault(); handleReceipt(item.id) }}>{item.quantity}</a></td>
                          <td>{item.amount}</td>
                          <td>{item.hindi}</td>
                          <td>{item.is_won == 1 && <i className="fa fa-trophy" style={{ fontSize: "18px", color: "#FFD700" }}></i>} {item.win_qty && "x " + item.win_qty}</td>
                          <td>{item.jackpot}</td>
                          <td>{item.win_amount}</td>
                          <td>{item.is_scan == 1 && <i className="fa fa-check" style={{ fontSize: "18px", color: "green" }}></i>}</td>
                          <td>{item.scan_at}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan="14" className="text-center text-muted">No data available</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="5"><b>Row Totals</b></td>
                    <td><b>{totalQuantity}</b></td>
                    <td><b>{totalAmount}</b></td>
                    <td><b>Total Wons</b></td>
                    <td><b>{totalWinQty}</b></td>
                    <td><b>{totalWinAmount}</b></td>
                    <td><b>Excess</b></td>
                    <td colSpan="3"><b>{parseInt(totalAmount) - parseInt(totalWinAmount)}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-center mt-1">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>

          </div>
        </section>
      </main>
    </>
  )
}
