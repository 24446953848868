import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone'; // Import moment-timezone library

const Clock = ({ gameType, setGame, currentGame, updateCurrentGame, setUpdateCurrentGame, freezeInput, checkJackpot, getJackpot, myDraws }) => {
  const [currentDate, setCurrentDate] = useState(moment.tz(moment(), 'Asia/Kolkata').format('DD-MM-YYYY'));
  const [currentTime, setCurrentTime] = useState(moment.tz(moment(), 'Asia/Kolkata').format('h:mm:ss A'));
  const [drawTime, setDrawTime] = useState(moment().format('hh:mm A'));
  const [reverseTime, setReverseTime] = useState('00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(moment.tz(moment(), 'Asia/Kolkata').format('DD-MM-YYYY'));
      setCurrentTime(moment.tz(moment(), 'Asia/Kolkata').format('h:mm:ss A'));
    }, 1000);

    let countdownEndTime = 0;
    let previousInterval = null;

    const resultInterval = setInterval(async () => {
      const currentMoment = moment();
      const interval = parseInt(gameType);

      // Calculate the nearest interval
      let nearestInterval = interval * Math.floor((currentMoment.minute() + interval) / interval);

      // Handle edge case when nearestInterval is 60
      if (nearestInterval >= 60) {
        nearestInterval = 0; // reset to the start of the next hour
        currentMoment.add(1, 'hour'); // move to the next hour
      }

      // Only update countdownEndTime if the nearestInterval has changed
      if (nearestInterval !== previousInterval) {
        previousInterval = nearestInterval;
        countdownEndTime = currentMoment.startOf('hour').minutes(nearestInterval);

        const newTime = countdownEndTime.format('hh:mm A');
        const timestamp = countdownEndTime.unix();

        setDrawTime(newTime);

        if (updateCurrentGame) {
          setGame({ game_type: gameType, timestamp: timestamp });
        }
      }
    }, 1000);

    const formatTime = (sec) => {
      const duration = moment.duration({ minutes: 0, seconds: sec });
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const reverseUpdateInterval = setInterval(() => {
      const currentTime = moment();
      const remainingTime = countdownEndTime.diff(currentTime, 'seconds');

      if (remainingTime <= 0) {
        myDraws(currentGame);
        setUpdateCurrentGame(true);
        setReverseTime('00:00');
        clearInterval(reverseUpdateInterval);
        freezeInput(false);
      } else {
        if (remainingTime <= 20) {
          freezeInput(true);
          if (checkJackpot && (remainingTime === 15 || remainingTime === 10 || remainingTime === 5)) {
            getJackpot(currentGame);
          }
        }
        setReverseTime(formatTime(remainingTime));
      }
    }, 1000);

    /* return () => {
      clearInterval(reverseUpdateInterval);
    }; */

    return () => { clearInterval(interval); clearInterval(resultInterval); clearInterval(reverseUpdateInterval); };
  }, [gameType, updateCurrentGame, setGame, freezeInput]);

  return (
    <>
      <span id='date'>{currentDate}</span>
      <span id='time'>{currentTime}</span>
      <span>{drawTime}</span>
      <span>{reverseTime}</span>
    </>
  );
};

export default Clock;