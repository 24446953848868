import React, { useEffect, useState } from 'react'
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import '../assets/css/style.css';
import Api from '../common/Api';
import { enCreption, decryptData } from '../common/Aes';
import { Icons, ToastContainer, toast } from 'react-toastify';
import ToastObj from '../common/ToastObj';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';

export default function Transactions() {
  const navigate = useNavigate();
  const { authData, logout } = useAuth();
  const auth_user = decryptData(authData);
  // Example data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [clearFilter, setClearFilter] = useState(false);
  const itemsPerPage = 25;

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [method, setMethod] = useState('');

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    fetchPageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (clearFilter)
      fetchPageData(0);
    return setClearFilter(false);
  }, [startDate,endDate,method]);

  const fetchPageData = async (page) => {
    try {
      const payload = await enCreption({ vendor_id: auth_user.id, page: page + 1, limit: itemsPerPage, from_date: startDate, to_date: endDate, method:method });
      await Api.post(`transactions`, payload)
        .then(res => {
          const response = res.data;
          setLoading(false);
          if (response.status === "success") {
            setData(response.items);
            setTotalPages(response.totalPages);
            setOpeningBalance(response.opening);
            setClosingBalance(response.closing);
          } else if (response.status === "error") {
            toast.error(response.msg, ToastObj);
          } else {
            toast.error("Something went wrong !!!", ToastObj);
          }
        });
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleDateFilter = () => {
    if (new Date(endDate) < new Date(startDate)) {
      Swal.fire({ icon: "error", text: 'End date cannot be earlier than start date.', allowOutsideClick: false });
      return;
    }
    setLoading(true);
    fetchPageData(0); // Reset to first page when filtering
  };

  const handleClearFilter = () => {
    setClearFilter(true);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setMethod("");
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };


  return (
    <>
      <main>
        <nav className="sticky-top custom_nav navbar navbar-expand-sm">
          <div className="container-fluid">
            <a className="navbar-brand" href=""><img src="assets/theme/images/galaxy-logo.png" className="img-fluid" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="d-flex right_text">
              <div><h4>{auth_user.vendor_id}</h4></div>
              <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/counter') }}>{"Counter"}</h4></div>
              <div><h4>{"Transactions"}</h4></div>
              <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/five-game') }}>{"5 Min Game"}</h4></div>
              <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/ten-game') }}>{"10 Min Game"}</h4></div>
            </div>
          </div>
        </nav>
        <section>
          <div className="container mt-1">
            <h2 className="mb-1 text-center">Transactions</h2>
            <div className="mb-1">
              <div className="row justify-content-center">
                <div className="col-md-3 mb-2">
                  <label htmlFor="startDate" className="form-label">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <label htmlFor="endDate" className="form-label">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <label htmlFor="endDate" className="form-label">Method</label>
                  <select
                    className="form-control"
                    id="endDate"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                  >
                  <option value={""}>--Select--</option>
                  <option value={"Cash"}>Cash</option>
                  <option value={"UPI"}>UPI</option>
                  <option value={"Bank Transfer"}>Bank Transfer</option>
                  <option value={"CLAIM"}>CLAIM</option>
                  <option value={"RECEIPT"}>RECEIPT</option>
                  <option></option>
                  </select>
                </div>
                <div className="col-md-3 d-flex justify-content-center align-items-end mb-2">
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleDateFilter}
                  >
                    Filter
                    {loading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="table-responsive" style={{ maxHeight: '50vh' }}>
              <table className="table table-striped table-bordered">
                <thead className="table-light">
                  <tr>
                    <th>Receipt No.</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Method</th>
                    <th>Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Opening Balance</b></td>
                    <td colSpan="2"><b>{openingBalance}</b></td>
                    <td colSpan={2}></td>
                  </tr>
                  {data.length > 0 ? (
                    data.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.bid_id}</td>
                          <td>{item.credit}</td>
                          <td>{item.debit}</td>
                          <td>{item.method}</td>
                          <td>{item.date}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center text-muted">No data available</td>
                    </tr>
                  )}
                  <tr>
                    <td><b>Closing Balance</b></td>
                    <td colSpan={2}><b>{closingBalance}</b></td>
                    <td colSpan={2}></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-center mt-0">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>

          </div>
        </section>
      </main>
    </>
  )
}
